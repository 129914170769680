import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  Button,
  Form,
  Icon,
  Input,
  Loader,
  Message,
  Modal,
  Popup,
  Table,
} from 'semantic-ui-react';

import { convertArrayToOptions } from '../utils/utils';

import useProfile from '../queries/useProfile';
import { useCategories } from '../queries/categories';
import { useParticipants } from '../queries/participants';
import {
  useAnonymousUsers,
  usePendingTransactions,
} from '../queries/transactionHistory';
import { useMaterialByBarcode, useMaterials } from '../queries/materials';
import { useSubcategories } from '../queries/subcategories';
import { useMaterialItems } from '../queries/materialItems';
import { useKdeys } from '../queries/kdeys';

import { MaterialItemList } from './ReusePickup';
import { useDebounce } from '@zerowaste/components';

export function RecycleCenterSelect({
  isTurnin = false,
  value,
  onChange,
  disabled = false,
  error,
}) {
  const { data: profile } = useProfile();

  const recycleCenters = useKdeys();

  const availableCenters = useMemo(
    () =>
      recycleCenters.data?.filter(({ id }) => id !== profile?.recycle_center) ??
      null,
    [profile?.recycle_center, recycleCenters.data]
  );

  return (
    <Form.Dropdown
      disabled={disabled}
      label="ΚΔΕΥ"
      name="recycle_center"
      selection
      selectOnNavigation={false}
      selectOnBlur={false}
      options={convertArrayToOptions({
        array: availableCenters,
        key: 'id',
        text: 'name',
        value: 'id',
      })}
      value={value || ''}
      onChange={(e, { value, options }) => {
        onChange('recycle_center', value || undefined);
        isTurnin && onChange('extraction_type', null);
      }}
      error={error && 'Αυτό το πεδίο δεν μπορεί να είναι κενό'}
    />
  );
}

export function UserSelect({
  isTurnin = false,
  emailSearch,
  setEmailSearch,
  email,
  name,
  onChange,
  userEmailDisabled,
  userTextDisabled,
  showAnonymous = true,
  emailError,
  resetErrors,
}) {
  const debouncedEmailSearch = useDebounce(emailSearch, 300);
  const users = useParticipants(
    { search: debouncedEmailSearch },
    {
      enabled: emailSearch.length > 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  const anonymousUsers = useAnonymousUsers({
    options: { enabled: showAnonymous },
  });
  return (
    <>
      <Form.Dropdown
        disabled={userEmailDisabled}
        label="Email πολίτη"
        name="user_email"
        selection
        clearable
        search
        noResultsMessage={
          users.isLoading ? (
            <Loader active inline size="mini" />
          ) : (
            !users.isIdle && 'Δεν βρέθηκαν αποτελέσματα'
          )
        }
        selectOnNavigation={false}
        selectOnBlur={false}
        options={convertArrayToOptions({
          array: users.data,
          key: 'id',
          text: 'email',
          value: 'email',
        })}
        searchQuery={emailSearch}
        value={email || ''}
        onSearchChange={(e, { searchQuery }) => {
          setEmailSearch(searchQuery);
        }}
        onChange={(e, { value, options }) => {
          setEmailSearch(value);
          onChange('user_email', value || undefined);
          onChange(
            'user_id',
            options.find((user) => user.value === value)?.key || undefined
          );

          isTurnin && onChange('extraction_type', 'REUSE');
        }}
        onBlur={() => {
          setEmailSearch(email || '');
        }}
        error={emailError && 'Αυτό το πεδίο δεν μπορεί να είναι κενό'}
        onClick={() => {
          resetErrors?.();
        }}
      />

      {showAnonymous && (
        <Form.Dropdown
          disabled={userTextDisabled}
          label="Όνομα μη-εγγεγραμμένου πολίτη"
          name="user_text"
          selection
          clearable
          search
          selectOnNavigation={false}
          selectOnBlur={false}
          noResultsMessage={null}
          options={
            anonymousUsers.data?.map((user, index) => ({
              key: index,
              text: user,
              value: user,
            })) || []
          }
          searchQuery={name || ''}
          value={name || ''}
          onSearchChange={(e, { name, searchQuery }) => {
            const val = searchQuery === '' ? undefined : searchQuery;
            onChange(name, val);
            isTurnin && onChange('extraction_type', 'REUSE');
          }}
          onChange={(e, { name, value }) => {
            const val = value === '' ? undefined : value;
            onChange(name, val);
            isTurnin && onChange('extraction_type', 'REUSE');
          }}
        />
      )}
    </>
  );
}

export function MaterialIncomingListModal({
  open,
  onClose,
  onSubmit,
  formMaterials = [],
}) {
  const handleSelectPendingMovement = ({
    id: transactionId,
    source,
    material,
    material_item,
    amount,
  }) => {
    onSubmit({
      category: material.subcategory.category.id,
      subcategory: material.subcategory.id,
      barcode: material.barcode,
      barcodeSearch: material.barcode,
      materialObj: material,
      amount: -amount,
      material: material.id,
      recycle_center_origin: source.id,
      recycle_center_movement_event: transactionId,
      item: material_item?.id,
      description: material_item?.description,
    });

    onClose();
  };

  const pendingMovements = usePendingTransactions();
  const movements = useMemo(
    () =>
      pendingMovements.data?.filter(({ id }) =>
        formMaterials.every(
          ({ recycle_center_movement_event }) =>
            recycle_center_movement_event !== id
        )
      ),
    [formMaterials, pendingMovements.data]
  );

  return (
    <Modal open={open} dimmer="inverted" onClose={onClose}>
      <Modal.Header>
        Επιλέξτε την μεταφορά από άλλο ΚΔΕΥ που παραλαμβάνετε
      </Modal.Header>
      <Modal.Content>
        {movements?.length === 0 ? (
          <Message
            warning
            header="Δεν εντοπίστηκαν εκκρεμείς μεταφορές προς αυτό το ΚΔΕΥ"
            content="Δεν μπορείτε να παραλάβετε υλικά από άλλο ΚΔΕΥ πριν περαστεί στην πλατφόρμα η εγγραφή παράδοσης προς αυτο το ΚΔΕΥ."
          />
        ) : (
          <Table selectable className="pointable">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>ΚΔΕΥ προέλευσης</Table.HeaderCell>
                <Table.HeaderCell>Ημ/νία & ώρα</Table.HeaderCell>
                <Table.HeaderCell>Barcode</Table.HeaderCell>
                <Table.HeaderCell>Υλικό</Table.HeaderCell>
                <Table.HeaderCell>Τεμάχια</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {movements?.map((transaction) => {
                const {
                  id,
                  source,
                  material,
                  amount,
                  material_display_name,
                  created,
                } = transaction;

                return (
                  <Table.Row
                    key={id}
                    onClick={() => handleSelectPendingMovement(transaction)}
                  >
                    <Table.Cell>{source.name}</Table.Cell>
                    <Table.Cell>
                      {new Date(created).toLocaleString('el')}
                    </Table.Cell>
                    <Table.Cell>{material.barcode}</Table.Cell>
                    <Table.Cell>{material_display_name}</Table.Cell>
                    <Table.Cell>{Math.abs(amount)}</Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={onClose}>
          Επιστροφή
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export function MaterialModal({
  open,
  formMaterial,
  onSubmit,
  onClose,
  isDelivery = false,
  isRecycleCenterMovement = false,
  amountError,
  setAmountError,
}) {
  const [material, setMaterial] = useState(() => ({}));
  useEffect(() => setMaterial(formMaterial || {}), [formMaterial]);

  const handleChange = useCallback((name, value) => {
    setMaterial((material) => ({ ...material, [name]: value }));
    setHasErrors(false);
  }, []);
  const [hasErrors, setHasErrors] = useState(false);

  const errors = {
    material: !material.material,
    amount: !material.amount,
    deliveryAmount: amountError,
    kilos:
      isDelivery && !isRecycleCenterMovement && !parseFloat(material.kilos),
    description:
      material.materialObj?.eligible && !isDelivery && !material.description,
    item: material.materialObj?.eligible && isDelivery && !material.item,
  };

  const handleSubmit = (material) => {
    if (Object.values(errors).some((val) => val === true)) {
      setHasErrors(true);
    } else {
      onSubmit(material);
      onClose();
    }
  };
  return (
    <Modal open={open} dimmer="inverted" onClose={onClose}>
      <Modal.Header>
        Στοιχεία υλικού προς {isDelivery ? 'παράδοση' : 'παραλαβή'}
      </Modal.Header>
      <Modal.Content>
        <MaterialSelect
          error={hasErrors && errors}
          isDelivery={isDelivery}
          isRecycleCenterMovement={isRecycleCenterMovement}
          setAmountError={setAmountError}
          kilos={material.kilos}
          category={material.category}
          subcategory={material.subcategory}
          barcode={material.barcode}
          barcodeSearch={material.barcodeSearch}
          materialObj={material.materialObj}
          amount={material.amount}
          description={material.description}
          item={material.item}
          handleChange={handleChange}
          isCollectionFromRecycleCenter={
            !!formMaterial?.recycle_center_movement_event
          }
        />
      </Modal.Content>
      <Modal.Actions>
        <Button positive onClick={() => handleSubmit(material)}>
          {formMaterial ? 'Ενημέρωση' : 'Προσθήκη'}
        </Button>
        <Button onClick={onClose}>Ακύρωση</Button>
      </Modal.Actions>
    </Modal>
  );
}

function MaterialSelect({
  error,
  category,
  subcategory,
  barcode,
  barcodeSearch,
  materialObj,
  amount,
  handleChange,
  isDelivery = false,
  isRecycleCenterMovement = false,
  kilos,
  setAmountError,
  description,
  item,
  isCollectionFromRecycleCenter = false,
}) {
  // allow no changes when collecting a material item
  const disableMaterialAmountChange = isCollectionFromRecycleCenter && !!item;

  const categories = useCategories({ params: { page_size: 400 } });
  const subcategories = useSubcategories({
    params: { page_size: 400, category },
    options: { enabled: !!category },
  });
  const materials = useMaterials({
    params: { page_size: 400, category, subcategory },
    options: { enabled: !!subcategory },
  });

  useMaterialByBarcode({
    params: { barcode },
    options: {
      enabled: !!barcode,
      onSuccess: (data) => {
        if (data.count > 0) {
          handleChange('category', data.results[0].subcategory.category.id);
          handleChange('subcategory', data.results[0].subcategory.id);
          handleChange('material', data.results[0].id);
          handleChange('materialObj', data.results[0]);
          isDelivery && handleChange('item', undefined);
          data.results[0].eligible && handleChange('amount', 1);
        }
      },
    },
  });

  const handleMaterialSelect = useCallback(
    (material) => {
      handleChange('material', material.id);
      handleChange('materialObj', material);
      handleChange('barcodeSearch', material.barcode);
      material.eligible && handleChange('amount', 1);
    },
    [handleChange]
  );

  // auto-select material if only one available anyway
  useEffect(() => {
    if (materials.data?.count === 1) {
      handleMaterialSelect(materials.data.results[0]);
    }
  }, [handleMaterialSelect, materials.data?.count, materials.data?.results]);

  const profile = useProfile();

  const materialAvailabilityByKdey =
    materialObj?.availability.find(
      ({ recycle_center }) => recycle_center.id === profile.data?.recycle_center
    )?.amount || 0;

  const handleCategorySelect = (name, value) => {
    handleChange(name, value);
    handleChange('subcategory', undefined);
    handleChange('material', undefined);
    handleChange('materialObj', undefined);
    handleChange('barcodeSearch', undefined);
    handleChange('barcode', undefined);
    handleChange('material', undefined);
    isDelivery && handleChange('item', undefined);
  };

  const handleSubcategorySelect = (name, value) => {
    handleChange(name, value);
    handleChange('material', undefined);
    handleChange('materialObj', undefined);
    handleChange('barcodeSearch', '');
    handleChange('barcode', undefined);
    handleChange('material', undefined);
    isDelivery && handleChange('item', undefined);
  };

  const handleBarcodeChange = (value) => {
    const matchBarcode = /^\d{1,2}.\d{3}.\d{3}$/;
    handleChange('barcodeSearch', value);
    if (matchBarcode.test(value)) {
      handleChange('barcode', value);
    }
  };

  const handleMaterialItemSelect = (item) => {
    handleChange('item', item?.id);
    handleChange('item_description', item?.description);
    handleChange('item_barcode', item?.barcode);
  };

  const materialItems = useMaterialItems(
    {
      material: materialObj?.id,
      is_available: true,
      recycle_center: profile.data?.recycle_center,
      page: 'all',
    },
    {
      enabled: !!materialObj?.eligible && profile.isFetched,
    }
  );

  // special error handling
  useEffect(() => {
    if (isDelivery && amount > materialAvailabilityByKdey) {
      setAmountError(true);
    } else {
      setAmountError?.(false);
    }
  }, [amount, isDelivery, materialAvailabilityByKdey, setAmountError]);

  useEffect(() => {
    error?.kilos && document.getElementById('material-form').scrollIntoView();
  }, [error?.kilos]);

  const availableAmountShown = materialObj
    ? materialAvailabilityByKdey !== undefined
      ? materialAvailabilityByKdey
      : 0
    : '';

  const displayKg = isDelivery && !isRecycleCenterMovement;

  return (
    <>
      <Form id="material-form">
        <Form.Group widths="equal">
          <Form.Dropdown
            disabled={disableMaterialAmountChange}
            className="nowrap"
            selection
            compact
            label="Κατηγορία"
            name="category"
            value={category || ''}
            onChange={(e, { name, value }) => {
              handleCategorySelect(name, value);
            }}
            placeholder="Επιλέξτε κατηγορία"
            loading={categories.isLoading}
            options={convertArrayToOptions({
              array: categories.data?.results,
              key: 'id',
              text: 'name',
              value: 'id',
            })}
          />
          <Form.Dropdown
            className="nowrap"
            selection
            label="Υποκατηγορία"
            name="subcategory"
            disabled={!category || disableMaterialAmountChange}
            value={subcategory || ''}
            onChange={(e, { name, value }) => {
              handleSubcategorySelect(name, value);
            }}
            placeholder="Επιλέξτε υποκατηγορία"
            loading={subcategories.isLoading}
            options={convertArrayToOptions({
              array: subcategories.data?.results,
              key: 'id',
              text: 'name',
              value: 'id',
            })}
          />
          <Form.Dropdown
            className="nowrap"
            selection
            label="Υλικό"
            name="material"
            disabled={!subcategory || disableMaterialAmountChange}
            value={JSON.stringify(materialObj) || ''}
            onChange={(e, { name, value }) => {
              handleChange(name, JSON.parse(value).id);
              handleChange('materialObj', JSON.parse(value));
              handleChange('barcodeSearch', JSON.parse(value).barcode);
              JSON.parse(value).eligible && handleChange('amount', 1);
            }}
            placeholder="Επιλέξτε υλικό"
            loading={materials.isLoading}
            options={convertArrayToOptions({
              array: materials.data?.results,
              key: 'id',
              text: 'body',
            })}
            error={error?.material && 'Αυτό το πεδίο δεν μπορεί να είναι κενό'}
          />
        </Form.Group>
        {materialObj?.eligible && !isDelivery && (
          <Form.Input
            label="Όνομα μοναδικού υλικού"
            name="description"
            value={description || ''}
            onChange={(e, { name, value }) => handleChange(name, value)}
            error={
              error?.description && 'Αυτό το πεδίο δεν μπορεί να είναι κενό'
            }
          />
        )}
        <Form.Group>
          <Form.Input
            width={displayKg ? 6 : 8}
            readOnly={disableMaterialAmountChange}
            label="Barcode υλικού"
            placeholder="Αναζήτηση μέσω barcode"
            name="barcode"
            value={barcodeSearch || ''}
            onChange={(e, { value }) => {
              handleBarcodeChange(value);
            }}
            onBlur={() => {
              if (materialObj) {
                handleChange('barcodeSearch', materialObj.barcode);
              }
            }}
          />
          <Form.Input
            width={displayKg ? 6 : 8}
            label={
              <Popup
                content={`Το πλήθος των τεμαχίων προς ${
                  isDelivery ? 'παράδοση' : 'παραλαβή'
                }`}
                trigger={
                  <label>
                    Πλήθος τεμαχίων <Icon name="info circle" />
                  </label>
                }
              />
            }
            disabled={
              !materialObj ||
              materialObj.eligible === true ||
              disableMaterialAmountChange
            }
            error={
              error?.deliveryAmount
                ? 'Το πλήθος ξεπερνάει τα διαθέσιμα τεμάχια'
                : error?.amount
                ? 'Αυτό το πεδίο δεν μπορεί να είναι κενό'
                : false
            }
          >
            <Input
              name="amount"
              type="number"
              input={{ inputMode: 'numeric', min: 0 }}
              value={amount || 0}
              label={{ content: `${availableAmountShown || 0} διαθέσιμα` }}
              labelPosition="right"
              onChange={(e, { name, value }) => {
                handleChange(name, parseInt(value));
              }}
            />
          </Form.Input>
          {displayKg && (
            <Form.Input
              width={4}
              label={
                <Popup
                  content="Τα κιλά του υλικού που θα παραδοθεί"
                  trigger={
                    <label>
                      Παράδοση Kg <Icon name="info circle" />
                    </label>
                  }
                />
              }
              name="kilos"
              type="number"
              input={{ inputMode: 'decimal', min: 0, step: 0.01 }}
              disabled={!materialObj}
              value={kilos || 0}
              onChange={(e, { name, value }) => {
                handleChange(name, value);
              }}
              error={error?.kilos && 'Αυτό το πεδίο δεν μπορεί να είναι κενό'}
            />
          )}
        </Form.Group>
        {isDelivery && materialObj?.eligible === true && (
          <>
            <MaterialItemList
              materialItems={materialItems.data}
              submitOrder={false}
              onSelect={handleMaterialItemSelect}
              selectedItem={item}
              disabledReserved={true}
              loading={materialItems.isLoading}
            />
            {materialItems.data?.length === 0 && (
              <Message info content="Δεν υπάρχουν διαθέσιμα μοναδικά υλικά" />
            )}
            {error?.item && (
              <Message
                color="red"
                content="Παρακαλώ επιλέξτε ένα διαθέσιμο μοναδικό υλικό"
              />
            )}
          </>
        )}
      </Form>
    </>
  );
}
