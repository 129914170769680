import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

import {
  Button,
  Container,
  Divider,
  Form,
  Header,
  Icon,
  Message,
  Segment,
  Table,
} from 'semantic-ui-react';

import useForm from '../hooks/useForm';
import { useMaterialImport } from '../queries/materials';

import {
  MaterialIncomingListModal,
  MaterialModal,
  UserSelect,
} from './TransactionForm';

export default function AdminPickup() {
  const pageTitle = 'Παραλαβή αντικειμένων';

  const {
    formData,
    handleMaterialUpdate,
    handleMaterialAdd,
    handleFormChange,
    handleFormClear,
    handleDelete,
  } = useForm();

  const [emailSearch, setEmailSearch] = useState('');

  const addMaterialDisabled =
    !formData.municipality_visit &&
    !formData.recycle_center_movement &&
    !formData.from_user;

  const userEmailDisabled =
    !!formData.user_text ||
    !!formData.municipality_visit ||
    !!formData.recycle_center_movement ||
    !formData.from_user;
  const userTextDisabled =
    !!formData.user_email ||
    !!formData.municipality_visit ||
    !!formData.recycle_center_movement ||
    !formData.from_user;

  const handleClear = () => {
    handleFormClear();
    setEmailSearch('');
  };

  const [showError, setShowError] = useState(false);
  const error =
    !(
      formData.user_email ||
      formData.user_text ||
      formData.recycle_center_movement ||
      formData.municipality_visit
    ) ||
    formData.materials.length === 0 ||
    !formData.materials.every(({ material, amount }) => !!material && !!amount);

  const materialImport = useMaterialImport();

  const handleSubmit = () => {
    const dataToSubmit = formData;
    if (!formData.from_user) {
      delete dataToSubmit.user_email;
      delete dataToSubmit.user_text;
    }
    if (error) {
      setShowError(true);
    } else {
      materialImport.mutate(
        {
          ...dataToSubmit,
          materials: [
            ...dataToSubmit.materials.map(
              ({
                material,
                amount,
                description,
                recycle_center_movement_event,
                item,
              }) => ({
                material,
                amount,
                description,
                recycle_center_movement_event,
                item,
              })
            ),
          ],
        },
        { onSuccess: handleClear }
      );
      setShowError(false);
    }
  };

  const isSubmitDisabled = materialImport.isLoading;

  const [isMaterialModalOpen, setIsMaterialModalOpen] = useState(false);
  const [incomingMaterialModalOpen, setIncomingMaterialModalOpen] =
    useState(false);

  const [materialIndex, setMaterialIndex] = useState();
  const handleModalOpen = (index, adding = false) => {
    if (formData.recycle_center_movement && adding) {
      setIncomingMaterialModalOpen(true);
    } else {
      setIsMaterialModalOpen(true);
    }

    setMaterialIndex(index);
  };
  const handleModalClose = () => {
    setIncomingMaterialModalOpen(false);
    setIsMaterialModalOpen(false);
  };

  const onAddMaterial = () => {
    handleModalOpen(formData.materials.length, true);
  };

  const handleCheckboxClick = (e, { name, checked }) => {
    const valuesToBeClear = [
      'from_user',
      'municipality_visit',
      'recycle_center_movement',
    ].filter((val) => val !== name);
    valuesToBeClear.forEach((name) => handleFormChange(name, false));
    handleFormChange(name, checked);
  };

  const handleMaterialSubmit = (material) => {
    if (formData.materials[materialIndex]) {
      handleMaterialUpdate(material, materialIndex);
    } else {
      handleMaterialAdd(material);
    }
  };

  return (
    <>
      <Helmet title={pageTitle} />
      <Container>
        <Header
          as="h1"
          textAlign="center"
          className="colored atmgreen"
          content={pageTitle}
        />
        <Divider className="orange" />
        <Divider hidden />
      </Container>
      <Container text>
        <Segment secondary placeholder={false} loading={false}>
          <Form
            onSubmit={handleSubmit}
            error={showError && error}
            success={materialImport.isSuccess}
          >
            <Form.Group style={{ justifyContent: 'center' }}>
              <Form.Radio
                checked={formData.from_user || false}
                onChange={handleCheckboxClick}
                label="Από πολίτη"
                name="from_user"
              />
              <Form.Radio
                checked={formData.municipality_visit || false}
                onChange={handleCheckboxClick}
                label="Από υπηρεσίες δήμου"
                name="municipality_visit"
              />
              <Form.Radio
                label="Από άλλο ΚΔΕΥ"
                name="recycle_center_movement"
                checked={formData.recycle_center_movement || false}
                onChange={handleCheckboxClick}
              />
            </Form.Group>
            <UserSelect
              emailSearch={emailSearch}
              setEmailSearch={setEmailSearch}
              email={formData.user_email}
              name={formData.user_text}
              onChange={handleFormChange}
              userEmailDisabled={userEmailDisabled}
              userTextDisabled={userTextDisabled}
            />

            <Divider />
            {formData.materials.length > 0 && (
              <>
                <Header as="h2" textAlign="center">
                  Υλικά
                </Header>
                <Table celled selectable className="pointable">
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Υλικό</Table.HeaderCell>
                      <Table.HeaderCell>Barcode</Table.HeaderCell>
                      <Table.HeaderCell>Τεμάχια</Table.HeaderCell>
                      <Table.HeaderCell
                        style={{ width: '40px' }}
                      ></Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {formData.materials.map((material, index) => (
                      <Table.Row
                        key={index}
                        onClick={() => handleModalOpen(index)}
                      >
                        <Table.Cell>
                          {material.materialObj.eligible
                            ? `${material.description} (${material.materialObj?.body})`
                            : material.materialObj?.body}
                        </Table.Cell>
                        <Table.Cell>{material.materialObj?.barcode}</Table.Cell>
                        <Table.Cell>{material.amount}</Table.Cell>
                        <Table.Cell>
                          <Button
                            type="button"
                            size="tiny"
                            negative
                            icon
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDelete(index);
                            }}
                          >
                            <Icon
                              name="close"
                              style={{ pointerEvents: 'none' }}
                            />
                          </Button>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </>
            )}
            <Form.Group style={{ justifyContent: 'space-around' }}>
              <Button
                type="button"
                positive
                onClick={onAddMaterial}
                disabled={addMaterialDisabled}
              >
                <Icon name="add" />
                {formData.recycle_center_movement
                  ? 'Προσθήκη υλικού από ΚΔΕΥ'
                  : 'Προσθήκη νέου υλικού'}
              </Button>
            </Form.Group>
            <Divider hidden />
            <Form.Group style={{ justifyContent: 'center' }}>
              <Form.Button
                primary
                content="Υποβολή"
                disabled={isSubmitDisabled}
                loading={materialImport.isLoading}
              />
              <Form.Button
                onClick={(e) => {
                  e.preventDefault();
                  handleClear();
                }}
                disabled={materialImport.isLoading}
                content="Καθαρισμός"
              />
            </Form.Group>

            <Message
              success
              header="Επιτυχία"
              content="Η παραλαβή καταχωρήθηκε επιτυχώς"
              onDismiss={() => materialImport.reset()}
            />
            <Message
              error
              content="Παρακαλώ συμπληρώστε σωστά τα στοιχεία της φόρμας"
              onDismiss={() => setShowError(false)}
            />
          </Form>
        </Segment>
        <Divider section hidden />

        <MaterialModal
          open={isMaterialModalOpen}
          formMaterial={formData.materials[materialIndex]}
          onClose={handleModalClose}
          onSubmit={handleMaterialSubmit}
        />

        <MaterialIncomingListModal
          open={incomingMaterialModalOpen}
          onClose={handleModalClose}
          onSubmit={handleMaterialSubmit}
          formMaterials={formData.materials}
        />
      </Container>
    </>
  );
}
