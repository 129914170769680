import { useState } from 'react';
import { Helmet } from 'react-helmet';

import {
  Button,
  Container,
  Divider,
  Form,
  Header,
  Icon,
  Message,
  Segment,
  Table,
} from 'semantic-ui-react';

import useForm from '../hooks/useForm';
import { useMaterialExport } from '../queries/materials';

import {
  MaterialModal,
  RecycleCenterSelect,
  UserSelect,
} from './TransactionForm';

export default function AdminTurnIn() {
  const pageTitle = 'Παράδοση αντικειμένων';

  const {
    formData,
    handleMaterialUpdate,
    handleMaterialAdd,
    handleFormChange,
    handleFormClear,
    handleDelete,
  } = useForm();

  const [emailSearch, setEmailSearch] = useState('');

  const [amountError, setAmountError] = useState(false);

  const userEmailDisabled =
    !!formData.user_text ||
    !!formData.municipality_visit ||
    !!formData.recycle_center_movement ||
    formData.extraction_type !== 'REUSE';
  const userTextDisabled =
    !!formData.user_email ||
    !!formData.municipality_visit ||
    !!formData.recycle_center_movement ||
    formData.extraction_type !== 'REUSE';
  const kdeySelectDisabled = !formData.recycle_center_movement;

  const handleClear = () => {
    handleFormClear();
    setEmailSearch('');
    setAmountError(false);
    setShowError(false);
  };

  const [showError, setShowError] = useState(false);
  const error =
    !(
      ((formData.user_email || formData.user_text) &&
        formData.delivery_note_number) ||
      (formData.recycle_center_movement && formData.recycle_center) ||
      formData.extraction_type === 'DISPOSE' ||
      formData.extraction_type === 'RECYCLE'
    ) ||
    formData.materials.length === 0 ||
    !formData.materials.every(
      ({ material, amount, kilos }) =>
        !!material && !!amount && (!!kilos || formData.recycle_center_movement)
    ) ||
    amountError;

  const materialExport = useMaterialExport();

  const handleSubmit = () => {
    if (error) {
      setShowError(true);
    } else {
      materialExport.mutate(
        {
          ...formData,
          materials: [
            ...formData.materials.map(({ material, amount, kilos, item }) => ({
              material,
              amount,
              kilos,
              item,
            })),
          ],
        },
        { onSuccess: handleClear }
      );
    }
  };

  const isSubmitDisabled = materialExport.isLoading;

  const [isMaterialModalOpen, setIsMaterialModalOpen] = useState(false);
  const [materialIndex, setMaterialIndex] = useState();
  const handleModalOpen = (index) => {
    setIsMaterialModalOpen(true);
    setMaterialIndex(index);
  };
  const handleModalClose = () => {
    setIsMaterialModalOpen(false);
  };

  const onAddMaterial = (e) => {
    e.preventDefault();
    handleModalOpen(formData.materials.length);
  };

  return (
    <>
      <Helmet title={pageTitle} />
      <Container>
        <Header
          as="h1"
          textAlign="center"
          className="colored atmgreen"
          content={pageTitle}
        />
        <Divider className="orange" />
        <Divider hidden />
      </Container>
      <Container text>
        <Segment secondary placeholder={false} loading={false}>
          <Form
            onSubmit={handleSubmit}
            error={showError && error}
            success={materialExport.isSuccess}
          >
            <Form.Group style={{ justifyContent: 'center' }}>
              <Form.Radio
                label="Επαναχρησιμοποίηση"
                name="extraction_type"
                value="REUSE"
                checked={formData.extraction_type === 'REUSE'}
                onChange={(e, { name, value }) => {
                  handleFormChange(name, value);
                  handleFormChange('recycle_center_movement', false);
                }}
              />

              <Form.Radio
                label="Ταφή"
                name="extraction_type"
                value="DISPOSE"
                checked={formData.extraction_type === 'DISPOSE'}
                onChange={(e, { name, value }) => {
                  handleFormChange(name, value);
                  handleFormChange('recycle_center_movement', false);
                }}
              />
              <Form.Radio
                label="Ανακύκλωση"
                name="extraction_type"
                value="RECYCLE"
                checked={formData.extraction_type === 'RECYCLE'}
                onChange={(e, { name, value }) => {
                  handleFormChange(name, value);
                  handleFormChange('recycle_center_movement', false);
                }}
              />
              <Form.Radio
                label="Μεταφορά προς άλλο ΚΔΕΥ"
                name="recycle_center_movement"
                checked={formData.recycle_center_movement || false}
                onChange={(e, { name, checked }) => {
                  handleFormChange(name, checked);
                  handleFormChange('extraction_type', null);
                }}
              />
            </Form.Group>

            <UserSelect
              isTurnin
              emailSearch={emailSearch}
              setEmailSearch={setEmailSearch}
              email={formData.user_email}
              name={formData.user_text}
              onChange={handleFormChange}
              userEmailDisabled={userEmailDisabled}
              userTextDisabled={userTextDisabled}
            />
            <Form.Input
              label="Αριθμός δελτίου παράδοσης"
              name="delivery_note_number"
              value={formData.delivery_note_number || ''}
              onChange={(e, { name, value }) => {
                const val = value === '' ? null : value;
                handleFormChange(name, val);
              }}
              disabled={formData.extraction_type !== 'REUSE'}
            />

            <RecycleCenterSelect
              isTurnin
              disabled={kdeySelectDisabled}
              value={formData.recycle_center}
              onChange={handleFormChange}
            />

            <Divider />
            {formData.materials.length > 0 && (
              <>
                <Header as="h2" textAlign="center">
                  Υλικά
                </Header>
                <Table celled selectable className="pointable">
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Υλικό</Table.HeaderCell>
                      <Table.HeaderCell>Barcode</Table.HeaderCell>
                      <Table.HeaderCell>Τεμάχια</Table.HeaderCell>
                      <Table.HeaderCell>Κιλά</Table.HeaderCell>
                      <Table.HeaderCell
                        style={{ width: '40px' }}
                      ></Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {formData.materials.map((material, index) => (
                      <Table.Row
                        key={index}
                        onClick={(e) => {
                          e.target.tagName !== 'BUTTON' &&
                            handleModalOpen(index);
                        }}
                      >
                        <Table.Cell>
                          {material.materialObj.eligible
                            ? `${material.item_description} (${material.materialObj?.body})`
                            : material.materialObj?.body}
                        </Table.Cell>
                        <Table.Cell>
                          {material.materialObj.eligible
                            ? material.item_barcode
                            : material.materialObj?.barcode}
                        </Table.Cell>
                        <Table.Cell>{material.amount}</Table.Cell>
                        <Table.Cell>{material.kilos || '-'}</Table.Cell>
                        <Table.Cell>
                          <Button
                            size="tiny"
                            negative
                            icon
                            onClick={(e) => {
                              e.preventDefault();

                              handleDelete(index);
                            }}
                          >
                            <Icon
                              name="close"
                              style={{ pointerEvents: 'none' }}
                            />
                          </Button>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </>
            )}
            <Form.Group style={{ justifyContent: 'space-around' }}>
              <Button positive onClick={onAddMaterial}>
                <Icon name="add" />
                Προσθήκη νέου υλικού
              </Button>
            </Form.Group>
            <Divider hidden />
            <Form.Group style={{ justifyContent: 'center' }}>
              <Form.Button
                primary
                content="Υποβολή"
                disabled={isSubmitDisabled}
                loading={materialExport.isLoading}
              />
              <Form.Button
                content="Καθαρισμός"
                onClick={(e) => {
                  e.preventDefault();
                  handleClear();
                }}
              />
            </Form.Group>
            <Message
              success
              header="Επιτυχία"
              content="Η παράδοση καταχωρήθηκε επιτυχώς"
              onDismiss={() => materialExport.reset()}
            />
            <Message
              error
              content="Παρακαλώ συμπληρώστε σωστά τα στοιχεία της φόρμας"
              onDismiss={() => setShowError(false)}
            />
          </Form>
        </Segment>
        <Divider section hidden />

        <MaterialModal
          open={isMaterialModalOpen}
          formMaterial={formData.materials[materialIndex]}
          onClose={handleModalClose}
          onSubmit={(material) => {
            !!formData.materials[materialIndex]
              ? handleMaterialUpdate(material, materialIndex)
              : handleMaterialAdd(material);
          }}
          isDelivery
          isRecycleCenterMovement={formData.recycle_center_movement}
          amountError={amountError}
          setAmountError={setAmountError}
        />
      </Container>
    </>
  );
}
